@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

* {
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

input, textarea, select, button {
  box-sizing: border-box;
  color: inherit;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  display: block;
}

input:focus, textarea:focus, button:focus {
  outline: none;
}

input[type="submit"]:hover, button:hover {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
}

textarea {
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

img, video, picture {
  display: block;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

summary::-webkit-details-marker {
  display: none;
}

:root {
  --done-color-00ff89: #00ff89;
  --done-color-333834: #333834;
  --done-color-ffffff: #fff;
  --done-color-000000: #000;
  --done-color-eaeaf6: #eaeaf6;
  --done-color-disable: #ccc;
  --done-color-danger: #cc4d36;
  --done-font-family-signal-mono: Signal Mono;
  --done-font-family-pp-neue-montreal: PP Neue Montreal;
  --done-font-style-normal: normal;
  --done-font-weight-normal: normal;
  --done-font-weight-medium: 600;
  --done-font-weight-bold: bold;
  --done-font-size-12: 1.2rem;
  --done-font-size-14: 1.4rem;
  --done-font-size-16: 1.6rem;
  --done-font-size-18: 1.8rem;
  --done-font-size-20: clamp(1.8rem, 2vw, 2rem);
  --done-font-size-22: clamp(2rem, 2.2vw, 2.2rem);
  --done-font-size-28: clamp(2.4rem, 2.4vw, 2.8rem);
  --done-font-size-32: clamp(2.4rem, 2.4vw, 3.2rem);
  --done-font-size-50: clamp(3.6rem, 3vw, 5rem);
  --done-font-size-60: clamp(5rem, 3vw, 6rem);
  --done-font-size-80: clamp(5.5rem, 4.5vw, 8rem);
  --done-font-size-86: clamp(6rem, 4.5vw, 8.6rem);
  --done-font-size-100: clamp(6rem, 6vw, 10rem);
  --done-font-size-140: clamp(6.2rem, 8vw, 14rem);
  --done-character-spacing--0-2: -.02rem;
  --done-character-spacing--0-22: -.022rem;
  --done-character-spacing--0-32: -.032rem;
  --done-character-spacing--0-5: -.05rem;
  --done-character-spacing--1-4: -.14rem;
  --done-character-spacing--0-16: -.016rem;
  --done-character-spacing--0-14: -.014rem;
  --done-line-spacing-20: 2rem;
  --done-line-spacing-25: 2.5rem;
  --done-line-spacing-28: clamp(2.4rem, 2.4vw, 2.8rem);
  --done-line-spacing-30: clamp(2.4rem, 2.4vw, 3.2rem);
  --done-line-spacing-55: clamp(4rem, 3vw, 5.5rem);
  --done-line-spacing-80: clamp(5.5rem, 4.5vw, 8rem);
  --done-line-spacing-100: clamp(6rem, 6vw, 10rem);
  --done-line-spacing-130: clamp(6.2rem, 8vw, 14rem);
  --done-text-transform-uppercase: uppercase;
  --done-large-devices: 992px;
}

html {
  font-size: 50%;
}

b {
  font-weight: var(--done-font-weight-bold);
}

@media only screen and (width >= 368px) {
  html {
    font-size: 62.5%;
  }
}

* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

a, button {
  all: unset;
  cursor: pointer;
}

a:hover, a:focus, a:active {
  text-decoration: underline;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

i {
  font-style: italic;
  display: block;
}

body {
  background: var(--done-color-eaeaf6);
}

table {
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid var(--done-color-333834);
  text-align: center;
  padding: 2rem;
}

.selectable {
  border-bottom: .6rem solid #0000;
}

.selectable:hover, .selectable:active, .selectable:focus {
  border-bottom: .6rem solid var(--done-color-00ff89);
}

.container {
  max-width: 192rem;
  margin: auto;
  position: relative;
}

.accent-color {
  color: var(--done-color-00ff89);
}

.no-break {
  white-space: nowrap;
}

.italic {
  font-style: italic;
}

.hidden {
  display: none;
}

.btn {
  cursor: pointer;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  text-transform: uppercase;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 6.6rem;
  padding: 0 1.6rem;
  display: inline-flex;
}

.btn-primary {
  color: var(--done-color-eaeaf6);
  background: linear-gradient(var(--done-color-000000), var(--done-color-000000) 50%, var(--done-color-00ff89) 50%, var(--done-color-00ff89));
  background-size: 100% 200%;
  transition: background .2s;
}

.btn-primary:hover:not([disabled]), .btn-primary:focus:not([disabled]) {
  color: var(--done-color-000000);
  background-position: 100% 100%;
  text-decoration: none;
}

.btn-primary[disabled] {
  border: 1px solid var(--done-color-disable);
  background: none;
}

.btn-secondary {
  color: var(--done-color-333834);
  background: linear-gradient(var(--done-color-eaeaf6), var(--done-color-eaeaf6) 50%, var(--done-color-333834) 50%, var(--done-color-333834));
  box-sizing: border-box;
  background-size: 100% 200%;
  transition: background .2s;
}

.btn-secondary:hover, .btn-secondary:focus {
  color: var(--done-color-eaeaf6);
  border: .2rem solid var(--done-color-eaeaf6);
  box-sizing: border-box;
  background-position: 100% 100%;
  text-decoration: none;
}

.btn-medium {
  width: 28rem;
}

.btn-large {
  width: 375px;
  max-width: 375px;
}

.btn-fast:hover, .btn-fast:focus {
  text-decoration: underline;
}

.done-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-140) / var(--done-line-spacing-130) var(--done-font-family-signal-mono);
  color: var(--done-color-eaeaf6);
  text-align: center;
  max-width: 85rem;
}

.done-subtitle {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-2);
}

.done-color-white {
  color: var(--done-color-ffffff);
}

.done-modal-section-header {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 25rem;
  padding: 3rem 6rem;
  display: flex;
}

.done-modal-section-header-dark {
  background: var(--done-color-000000) 0% 0% no-repeat padding-box;
  color: var(--done-color-ffffff);
}

.done-modal-section-header-image {
  color: var(--done-color-00ff89);
  height: 25rem;
  margin-top: 6rem;
}

.done-modal-section-header-callback {
  background-image: linear-gradient(#000000ab 0%, #0000 150%), url("callback.6b177d51.webp");
  background-size: cover;
}

.done-modal-close {
  color: var(--done-color-000000);
  width: 41px;
  height: 41px;
  position: absolute;
  top: 1.6rem;
  right: .1rem;
}

.done-modal-close-callback {
  color: var(--done-color-ffffff);
}

.done-modal-close svg {
  width: 100%;
  height: 100%;
}

.done-modal-close svg use {
  fill: var(--done-color-000000);
}

.done-modal-submit {
  justify-content: center;
  width: 100%;
  margin: 6rem 0 2rem;
  padding: 1rem;
  display: flex;
}

.done-modal-submit button {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.done-modal-submit svg {
  width: 100%;
  height: 100%;
}

.done-modal-submit svg use {
  fill: var(--done-color-000000);
}

.done-modal-submit-newsletter:disabled {
  color: var(--done-color-disable);
  cursor: not-allowed;
}

.done-modal-content {
  background-color: var(--done-color-00ff89);
  width: 100%;
  max-width: 100%;
  min-height: 60rem;
  max-height: 100%;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.done-modal-content-white {
  background: var(--done-color-ffffff);
  color: var(--done-color-000000);
}

.done-modal-content-section {
  padding: 1.6rem 1.6rem 0;
}

.done-modal-content .submitted-message {
  text-transform: uppercase;
  text-align: center;
  margin-top: 3rem;
}

.done-modal-content-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-80) / var(--done-line-spacing-80) var(--done-font-family-signal-mono);
  color: var(--done-color-eaeaf6);
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

.done-modal-content-title svg {
  width: 3rem;
  height: .75em;
}

.done-modal-content-economy {
  text-align: center;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
}

.done-modal-content-subtitle {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--1-4);
  text-align: left;
  padding: 1rem 0;
}

.done-modal-content-notice {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-16) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-16);
  padding: 3.2rem 0 0;
}

.done-modal-content-paragraph {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-16) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-16);
  text-align: left;
}

.done-modal-content-note {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-14) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-14);
  text-align: left;
  font-style: italic;
}

.done-modal-newsletter-content {
  padding: 2rem 7rem;
}

.done-modal-newsletter-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  text-transform: uppercase;
}

.done-input-group {
  flex-wrap: wrap;
  justify-content: center;
  height: calc(50% + 3.2rem);
  display: flex;
}

.done-input-group-newsletter {
  height: unset;
  justify-content: flex-start;
}

.done-input-group-newsletter label {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.done-input-group-newsletter.hs-form {
  justify-content: space-around;
  gap: 2rem;
  padding: 3rem 6rem;
}

.done-input-group-newsletter .hs-richtext {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
}

.done-input-group-newsletter .hs-richtext a {
  text-decoration: underline;
}

.done-input-group-newsletter .hs-email {
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.done-input-group-newsletter .hs-email .done-input-group-input {
  background: var(--done-color-ffffff);
  text-transform: uppercase;
  margin: 0;
  padding-left: 2.2rem;
}

.done-input-group-input, .done-input-group .hs-input {
  border: .1rem solid var(--done-color-000000);
  background: var(--done-color-eaeaf6);
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 7.5rem;
  margin: 3.2rem 0;
  padding-right: 2.2rem;
  display: flex;
}

.done-input-group-input-secondary, .done-input-group .hs-input-secondary {
  background: var(--done-color-eaeaf6);
  border: .1rem solid #0000;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 7.5rem;
  margin: 3.2rem 0;
  padding-right: 2.2rem;
  display: flex;
  box-shadow: 0 4px 4px #0000001a;
}

.done-input-group-input-secondary:focus-within, .done-input-group .hs-input-secondary:focus-within {
  border-color: var(--done-color-000000);
}

.done-input-group-input-secondary > input, .done-input-group .hs-input-secondary > input {
  min-width: 100px;
}

.done-input-group-input-secondary > input::placeholder, .done-input-group .hs-input-secondary > input::placeholder {
  color: var(--done-color-000000);
  opacity: .22;
}

.done-input-group-input-secondary > textarea, .done-input-group .hs-input-secondary > textarea {
  height: 100%;
}

.done-input-group-input-secondary > textarea::placeholder, .done-input-group .hs-input-secondary > textarea::placeholder {
  color: var(--done-color-000000);
  opacity: .21;
}

.done-input-group-input-newsletter, .done-input-group .hs-input-newsletter {
  margin-top: 0;
}

.done-input-group-input.has-danger, .done-input-group .hs-input.has-danger {
  border-color: var(--done-color-danger);
  color: var(--done-color-danger);
}

.done-input-group-input.has-danger svg, .done-input-group .hs-input.has-danger svg {
  fill: var(--done-color-danger);
}

.done-input-group-input.has-danger input, .done-input-group-input.has-danger-icon, .done-input-group-input.has-danger input::placeholder, .done-input-group .hs-input.has-danger input, .done-input-group .hs-input.has-danger-icon, .done-input-group .hs-input.has-danger input::placeholder {
  color: var(--done-color-danger);
}

.done-input-group-input-textarea, .done-input-group .hs-input-textarea {
  height: initial;
  align-items: start;
  min-height: 16rem;
}

.done-input-group-input-textarea textarea, .done-input-group .hs-input-textarea textarea {
  resize: none;
}

.done-input-group-input-callback, .done-input-group .hs-input-callback {
  margin: 1.6rem 0;
}

.done-input-group-input:focus-within, .done-input-group .hs-input:focus-within {
  outline: .1rem solid var(--done-color-000000);
}

.done-input-group-input-style, .done-input-group .hs-input-style {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  color: var(--done-color-000000);
  text-transform: uppercase;
  border: none;
  flex: 1;
  padding-inline-start: 2.2rem;
}

.done-input-group-input-style:focus, .done-input-group .hs-input-style:focus {
  outline: none;
}

.done-input-group-input-style-textarea, .done-input-group .hs-input-style-textarea {
  padding-block: 1.6rem;
}

.done-input-group-input button:focus, .done-input-group-input button:hover, .done-input-group .hs-input button:focus, .done-input-group .hs-input button:hover {
  text-decoration: underline;
}

.done-input-group-input-icon, .done-input-group .hs-input-icon {
  align-items: center;
  gap: 3.2rem;
  display: flex;
}

.done-input-group-input-icon:disabled, .done-input-group .hs-input-icon:disabled {
  color: var(--done-color-disable);
  cursor: not-allowed;
  text-decoration: none;
}

.done-input-group-input-icon svg, .done-input-group .hs-input-icon svg {
  width: 3.9rem;
}

.done-input-group-input-icon:focus, .done-input-group-input-icon:hover, .done-input-group-input-icon:active, .done-input-group .hs-input-icon:focus, .done-input-group .hs-input-icon:hover, .done-input-group .hs-input-icon:active {
  text-decoration: underline;
}

.done-input-group-asterisk {
  font: italic normal var(--done-font-weight-medium) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  text-align: left;
}

.done-nav {
  color: var(--done-color-eaeaf6);
  text-transform: uppercase;
  opacity: 1;
  z-index: 4;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  max-width: 192rem;
  padding: 1.6rem 2rem;
  transition: all .7s;
  display: inline-flex;
  position: fixed;
  top: 0;
}

.done-nav-account {
  align-items: center;
  display: flex;
}

.done-nav-account svg {
  width: 3.9rem;
  height: 3.9rem;
  margin-right: 1.6rem;
}

.done-nav > a.logo {
  width: 15.1rem;
  margin-right: 2.4rem;
}

.done-nav > a.logo > svg {
  width: 100%;
}

.done-nav-dark {
  color: var(--done-color-000000);
}

.done-nav-dark.done-nav-scroll-up, .done-nav-dark.done-nav-scroll-down {
  background: var(--done-color-ffffff);
}

.done-nav-scroll-down {
  background: var(--done-color-eaeaf6);
  color: var(--done-color-000000);
  transition: all .7s;
  top: -15.1rem;
}

.done-nav-scroll-down > a.logo {
  width: 8.6rem;
}

.done-nav-scroll-down .done-nav-mobile {
  height: 3.5rem;
  transition: all .7s;
}

.done-nav-scroll-down .done-nav-mobile-burger {
  width: 4rem;
  transition: all .7s;
}

.done-nav-scroll-up {
  background: var(--done-color-eaeaf6);
  color: var(--done-color-000000);
  transition: all .7s;
  top: 0;
}

.done-nav-scroll-up > a.logo {
  width: 8.6rem;
}

.done-nav-scroll-up .done-nav-mobile {
  height: 3.5rem;
  transition: all .7s;
}

.done-nav-scroll-up .done-nav-mobile-burger {
  width: 4rem;
  transition: all .7s;
}

.done-nav-links {
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.done-nav-resources {
  padding: 2.4rem 0;
}

.done-nav-resources:hover, .done-nav-resources:active, .done-nav-resources:focus-within {
  color: var(--done-color-000000);
}

.done-nav-resources:hover .done-subnav, .done-nav-resources:active .done-subnav, .done-nav-resources:focus-within .done-subnav {
  top: 0;
}

.done-nav-resources:hover svg, .done-nav-resources:active svg, .done-nav-resources:focus-within svg {
  transform: rotate(-180deg);
}

.done-nav-link {
  transition: all .5s;
}

.done-nav-link-within {
  align-items: center;
  gap: 3.6rem;
  display: flex;
}

.done-nav-link-within svg {
  width: 2rem;
  transition: all .3s;
  transform: rotate(0);
}

.done-nav-link .done-subnav {
  background-color: var(--done-color-eaeaf6);
  color: var(--done-color-000000);
  z-index: -1;
  flex-direction: column;
  width: 24rem;
  margin-left: -3.6rem;
  padding-top: 12.7rem;
  transition: all .8s;
  display: flex;
  position: absolute;
  top: -515%;
}

.done-nav-link .done-subnav-link {
  padding: 2.4rem 3.6rem;
}

.done-nav-link .done-subnav-link:hover, .done-nav-link .done-subnav-link:active, .done-nav-link .done-subnav-link:focus {
  background-color: #00ff89;
}

.done-nav-mobile {
  cursor: pointer;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 5.1rem;
  display: flex;
}

.done-nav-mobile-link {
  border-bottom: var(--done-color-eaeaf6) .1rem solid;
  width: 80%;
  height: 100%;
  padding: 4.2rem 0 1.2rem 1.2rem;
}

.done-nav-mobile-link-within {
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

.done-nav-mobile-link-within svg {
  width: 2rem;
  transition: all .3s;
  transform: rotate(0);
}

.done-nav-mobile-link-within.active svg {
  transform: rotate(-180deg);
}

.done-nav-mobile-burger {
  width: 5.5rem;
}

.done-nav-mobile-menu {
  opacity: 8;
  z-index: 5;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

.done-nav-mobile-open {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2rem;
  display: inline-flex;
}

.done-nav-mobile-open > a.logo {
  width: 15.1rem;
  margin-right: 2.4rem;
}

.done-nav-mobile-open > a.logo > svg {
  width: 100%;
}

.done-nav-mobile-open > button > svg {
  width: 4rem;
}

.done-nav-mobile-links {
  text-align: left;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 52rem;
  display: flex;
}

.done-nav-mobile-account {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10.2rem;
  padding-top: 4.8rem;
  display: flex;
}

.done-nav-mobile-account svg {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
}

.done-subnav-mobile {
  flex-direction: column;
  justify-content: space-evenly;
  max-height: 0;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.done-subnav-mobile-link {
  margin-top: 1.2rem;
}

.done-subnav-mobile.active {
  max-height: 33.55rem;
  transition: all .3s;
}

.done-subnav-mobile-link {
  padding: 1.2rem 2.4rem;
}

.done-section {
  width: 100%;
  padding: 6.8rem 2.8rem;
}

.done-section-light {
  background: var(--done-color-eaeaf6) 0% 0% no-repeat padding-box;
  color: var(--done-color-000000);
}

.done-section-dark {
  background: var(--done-color-333834) 0% 0% no-repeat padding-box;
  color: var(--done-color-eaeaf6);
  fill: var(--done-color-eaeaf6);
}

.done-section-white {
  background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
  color: var(--done-color-000000);
}

.done-section-modal {
  position: relative;
}

.done-section-margin-button {
  max-width: 80%;
  margin: auto;
}

.done-section-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-100) / var(--done-font-size-100) var(--done-font-family-signal-mono);
  font-size: var(--done-font-size-60);
  text-align: left;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.done-section-title-simulator {
  display: block;
}

.done-section-title-vision-svg {
  align-self: flex-end;
  height: 7.2rem;
  display: flex;
}

.done-section-title-svg {
  align-self: flex-start;
  height: 7.2rem;
}

.done-section-title-shared {
  flex-direction: column;
  justify-content: space-between;
  gap: 3.2rem;
  display: flex;
}

.done-section-title-shared p {
  flex-basis: 25%;
}

.done-section-title-second {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  margin: 4.4rem 0;
}

.done-section-title-second-vision {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  margin: 4.4rem 0;
}

.done-section-title-third {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  text-align: center;
}

.done-section-title-third svg {
  height: 2rem;
}

.done-section-title-fourth {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-22) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-22);
  text-align: left;
}

.done-section-title-fourth > svg {
  height: 1.5rem;
}

.done-section-title-timeline {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.done-section-title-center {
  text-align: center;
  padding: 0 1rem;
}

.done-section-subtitle {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  text-align: center;
  opacity: 1;
  width: 100%;
  padding: 3.6rem 0;
}

.done-section-step {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.done-section-step-one {
  width: 17.19rem;
  margin-bottom: 3rem;
}

.done-section-step-one img {
  aspect-ratio: auto 573 / 200;
}

.done-section-step-image {
  height: 6rem;
}

.done-section-step-two {
  text-align: end;
  align-self: flex-end;
  width: 22.782rem;
}

.done-section-step-two img {
  aspect-ratio: auto 760 / 200;
}

.done-section-step-three {
  width: 24.678rem;
}

.done-section-step-three img {
  aspect-ratio: auto 827 / 200;
}

.done-section-step-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-align: center;
  text-transform: uppercase;
  padding-top: 3.6rem;
  padding-bottom: 8rem;
}

.done-section-step-subtitle {
  margin-top: 1.8rem;
  font-size: 2.2rem;
}

.done-section-step > div {
  margin: 1.6rem 0;
}

.done-section-step > .done-section-step-unreveal {
  margin-left: -50%;
}

.done-section-step > .done-section-step-unreveal-right {
  margin-left: 0;
  margin-right: -50%;
}

.done-section-step > .done-section-step-reveal {
  margin-left: 0;
  margin-right: 0;
  transition: all .5s;
}

.done-section-button-group {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.6rem;
  width: 100%;
  display: flex;
}

.done-section-step .done-section-button-group {
  margin-top: 4rem;
}

.done-section-photos {
  max-height: 60rem;
  display: inline-flex;
}

.done-section-photos img {
  aspect-ratio: auto 6 / 7;
}

.done-section-photos-mobile {
  object-fit: cover;
  width: 100%;
  display: block;
}

.done-section-photos-desktop {
  object-fit: cover;
  width: 33%;
  display: none;
}

.done-section-mini {
  flex-direction: column;
  justify-content: space-between;
  padding: 6.8rem 0;
  display: flex;
}

.done-section-mini-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  margin-bottom: 6rem;
}

.done-section-mini-picture {
  display: flex;
}

.done-section-mini-picture svg {
  display: none;
}

.done-section-mini-picture-image {
  width: 100%;
  max-width: 46rem;
  max-height: 46rem;
}

.done-section-mini-text {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  align-self: flex-end;
  padding: 3.6rem 0 0;
}

.done-section-mini-description {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  align-self: flex-end;
}

.done-section-mini-description p:not(:first-child) {
  margin-top: 2.4rem;
}

.done-section-sub {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.done-section-sub-part {
  flex-basis: 100%;
}

.done-section-sub-part:not(:first-child) {
  text-align: center;
  text-align: -webkit-center;
}

.done-section-sub-part:nth-child(2) {
  align-self: center;
}

.done-section-sub-part-icon-desktop {
  display: none;
}

.done-section-sub-part-icon-desktop-small {
  width: 10rem;
  display: none;
}

.done-section-sub-part-icon-mobile {
  align-self: center;
  width: 7.2rem;
  margin-top: 7.2rem;
  display: block;
}

.done-section-sub-impact-list {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.done-section-sub-impact-detail {
  margin-top: 7.5rem;
}

.done-section-sub-impact-detail-number {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-80) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
}

.done-section-sub-impact-detail-text {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
}

.done-section-avantages {
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem;
  display: flex;
}

.done-section-avantages-text {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-100) / var(--done-font-size-100) var(--done-font-family-signal-mono);
  color: var(--done-color-000000);
  width: 100%;
}

.done-section-avantages-text-associations {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
}

.done-section-avantages-text > span {
  color: var(--done-color-00ff89);
}

.done-section-avantages-list {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  color: var(--done-color-000000);
}

.done-section-avantages-list > ul {
  margin-bottom: 8rem;
}

.done-section-avantages-list-text {
  cursor: pointer;
  color: var(--done-color-000000);
  margin-bottom: 2.5rem;
}

.done-section-avantages-list-text:hover, .done-section-avantages-list-text:focus {
  color: var(--done-color-00ff89);
}

.done-section-avantages-list-text.active {
  text-decoration: underline;
}

.done-section-avantages-list-description {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  color: var(--done-color-000000);
  margin-bottom: 2.5rem;
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.done-section-avantages-list-description.visible {
  height: 100%;
  animation: 2s fadeIn;
  display: block;
  overflow: hidden;
}

.done-section-avantages-arrow {
  display: none;
}

.done-section-avantages-arrow > img {
  width: 9.2rem;
}

.done-section-pig {
  background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  height: 7.5rem;
  overflow: hidden;
}

.done-section-pig-inner {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-30) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  color: var(--done-color-000000);
  transform: translate3d(var(--move-initial), 0, 0);
  width: 800%;
  height: 100%;
  animation: 8s linear infinite marquee;
  display: flex;
  position: relative;
}

.done-section-pig-inner:hover {
  animation-play-state: paused;
}

.done-section-pig-animate {
  text-align: left;
  flex-grow: 2;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.done-section-pig-animate svg {
  width: 6rem;
}

.done-section-promise {
  background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  height: 7.5rem;
  overflow: hidden;
}

.done-section-promise-inner {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-30) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  color: var(--done-color-000000);
  transform: translate3d(var(--move-initial), 0, 0);
  width: 800%;
  height: 100%;
  animation: 8s linear infinite marquee;
  display: flex;
  position: relative;
}

.done-section-promise-inner:hover {
  animation-play-state: paused;
}

.done-section-promise-animate {
  text-align: left;
  flex-grow: 2;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.done-section-promise-animate svg {
  width: 5rem;
  margin-right: 1.1rem;
}

.done-section-promise-animate-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.done-section-cta {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.done-section-cta-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-align: center;
  flex-basis: 100%;
}

.done-section-cta-logo {
  height: 12.5rem;
  margin: 7rem 0;
}

.done-section-timeline {
  flex-direction: row;
  display: flex;
}

.done-section-timeline-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  color: var(--done-color-000000);
  flex-direction: column;
  justify-content: flex-start;
  margin: 4.4rem 0 0;
  display: flex;
}

.done-section-timeline-title-detail {
  opacity: 0;
  width: 100%;
  margin: 1.6rem;
  transition: all 1s;
}

.done-section-timeline-title-detail-visible {
  opacity: 1;
}

.done-section-timeline-title-detail br {
  display: none;
}

.done-section-timeline-description {
  justify-content: space-between;
  margin: 4.4rem 0;
  display: none;
}

.done-section-timeline-description-detail {
  width: 100%;
  min-height: 27rem;
  margin: 1.6rem 0;
}

.done-section-timeline-list {
  flex-direction: column;
  order: -1;
  justify-content: space-between;
  margin: 6rem 1.6rem 0 0;
  display: flex;
}

.done-section-timeline-detail {
  flex-grow: 1;
  position: relative;
}

.done-section-timeline-detail:before {
  content: "";
  border: .1rem solid var(--done-color-000000);
  box-sizing: border-box;
  cursor: pointer;
  width: 4.4rem;
  height: 4.4rem;
  display: block;
}

.done-section-timeline-detail:hover:before {
  background: var(--done-color-000000);
}

.done-section-timeline-detail-active:before {
  background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
  border: none;
}

.done-section-timeline-detail:after {
  content: "";
  border-left: .125rem dashed var(--done-color-000000);
  width: .1rem;
  height: calc(100% - 4.4rem);
  position: absolute;
  top: 4.4rem;
  left: 2.2rem;
}

.done-section-button {
  margin: 6.6rem 0 0;
}

.done-section-form {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2.4rem;
  width: 100%;
  display: flex;
}

.done-section-form-group {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  flex-direction: column;
  flex-basis: 100%;
  gap: 1.6rem;
  width: 100%;
  display: flex;
}

.done-section-form-group-paragraph {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  color: var(--done-color-000000);
  text-align: left;
}

.done-section-form-button {
  flex-basis: 100%;
  justify-content: center;
  padding: 7.5rem 0 0;
  display: flex;
}

.done-section-cover {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

.done-section-cover img {
  width: 100%;
}

.done-section-company {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  width: 100%;
  padding: 2.4rem 1.6rem;
  display: flex;
}

.done-section-stories {
  align-items: stretch;
  display: flex;
}

.done-section-stories-container {
  background: linear-gradient(#00000080 0%, #0003 100%), url("stories_photo.b24e66e3.webp") bottom / cover no-repeat;
  width: 100%;
  min-height: 50vh;
  padding: 2.8rem 2.8rem 11.3rem;
  position: relative;
}

.done-section-stories-horizontal-container {
  height: 100%;
  display: flex;
}

.done-section-stories-photo {
  background: linear-gradient(#00000080 0%, #0003 100%), url("stories_photo.b24e66e3.webp") bottom / cover no-repeat;
  flex: 50%;
  display: none;
}

.done-section-stories-sub-container {
  flex-direction: column;
  flex: 50%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.done-section-stories-top {
  flex: 1;
}

.done-section-stories-bottom {
  text-align: center;
}

.done-section-stories-bottom .done-page-details {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.done-section-stories-bottom .done-page-stories-destination {
  text-align: center;
}

.done-section-stories-bottom .done-page-stories-destination-details {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  max-width: 22.2rem;
  margin: 0 auto 3.8rem;
}

.done-section-stories-bottom .done-page-stories .btn {
  margin: 0 auto;
}

.done-section-stories-arrow {
  display: none;
}

.done-section-stories-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  text-align: center;
  text-transform: uppercase;
  max-width: 25rem;
  margin: 7rem auto 3.7rem;
}

.done-section-stories-subtitle {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  text-align: center;
  max-width: 31rem;
  margin: 0 auto;
}

.done-section-half {
  flex-direction: column;
  display: flex;
}

.done-section-half-one {
  width: 100%;
}

.done-section-half-one-img {
  width: 100%;
  height: 100%;
}

.done-section-half-two {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 3.2rem 1.6rem;
  display: flex;
}

.done-section-half-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  margin-bottom: 3.2rem;
}

.done-section-half-description {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-2);
}

.done-section-half-description p:not(:first-child) {
  margin-top: 2.4rem;
}

.done-section-faq {
  width: 100%;
  padding: 3.2rem 0;
}

.done-section-faq-home {
  padding-bottom: 0;
}

.done-section-faq-title {
  text-align: center;
}

.done-section-faq-group {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  border-top: var(--done-color-eaeaf6) .1rem solid;
  border-bottom: var(--done-color-eaeaf6) .1rem solid;
  box-sizing: border-box;
  cursor: pointer;
  height: 11.8rem;
  min-height: 11.8rem;
  padding: 0 1.6rem;
}

.done-section-faq-group-light {
  border-top: var(--done-color-333834) .1rem solid;
  border-bottom: none;
}

.done-section-faq-group[open] {
  height: auto;
}

.done-section-faq-group[open] svg {
  transition: rotate .7s;
  rotate: 180deg;
}

.done-section-faq-group ul {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  padding-bottom: 1.6rem;
}

.done-section-faq-group ul li {
  list-style: disc;
}

.done-section-faq-group svg {
  flex-shrink: 0;
  width: 3.8rem;
  height: 3.8rem;
  transition: rotate .7s;
  rotate: 360deg;
}

.done-section-faq-group-collapse {
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 11.8rem;
  display: flex;
}

.done-section-faq-group-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  text-transform: uppercase;
}

.done-section-faq-group-paragraph {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
  padding-bottom: 1.6rem;
}

.done-section-faq-group-more {
  display: none;
}

.done-section-button {
  max-width: 100%;
  margin: 6.6rem 0 0;
  display: flex;
}

.done-section-paragraph {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-22);
  color: var(--done-color-000000);
  text-align: left;
}

.done-section-paragraph-second {
  margin-top: 2.4rem;
}

.done-section-unsold {
  flex-wrap: wrap;
  display: flex;
}

.done-section-unsold-title {
  text-align: center;
  margin-bottom: 6.6rem;
}

.done-section-unsold-button {
  width: 100%;
  margin-bottom: 7.6rem;
  padding-top: 7.6rem;
  display: none;
}

.done-section-unsold-button-mobile {
  justify-content: center;
  margin-top: 6.6rem;
  display: flex;
}

.done-section-unsold-first {
  flex-wrap: wrap;
  display: flex;
}

.done-section-unsold-second {
  text-align: center;
}

.done-section-unsold-second-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  margin-bottom: 1.5rem;
}

.done-section-unsold-second-text {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
}

.done-section-impact {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.done-section-impact-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-32);
  text-align: center;
}

.done-section-impact-list {
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: space-evenly;
  padding: 10rem 0 0;
  display: flex;
}

.done-section-impact-detail {
  text-align: center;
  margin-bottom: 6.8rem;
}

.done-section-impact-detail:last-child {
  margin-bottom: 0;
}

.done-section-impact-detail svg {
  width: 10rem;
}

.done-section-impact-detail-number {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  padding: 0;
}

.done-section-impact-detail-text {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
}

.done-page-stories {
  margin: 3.2rem 0;
}

.done-page-stories-destination {
  text-align: left;
}

.done-page-stories-destination-details {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  max-width: 22.2rem;
  margin: 0 auto 3.8rem;
}

.done-page-stories-destination-span {
  grid-column: 1 / span 2;
}

.section-header {
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section-header-vision {
  background-image: url("animated-picto-green.fe65e5a3.gif");
  background-position: 50% 26%;
  background-size: 40rem;
  flex-direction: column;
  position: relative;
}

@media only screen and (width >= 368px) {
  .section-header-vision {
    background-size: 35rem;
  }
}

.section-header-vision-arrow {
  left: inherit;
  width: 7.2rem;
  position: absolute;
  bottom: 3rem;
}

.section-header-video {
  z-index: 1;
  background: var(--done-color-eaeaf6);
  width: inherit;
  height: 100vh;
  min-height: 76.8rem;
  position: absolute;
}

.section-header-video-background {
  opacity: 1;
  z-index: 2;
  background: linear-gradient(#000a 0%, #0000 100%) no-repeat padding-box padding-box;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
}

.subsection-title {
  z-index: 3;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  padding: 0;
  display: flex;
}

.subsection-title > svg {
  color: var(--done-color-eaeaf6);
  width: 7.8rem;
}

.subsection-social {
  z-index: 3;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 1.6rem 2rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.subsection-social > a {
  width: 3.2rem;
  height: 3.2rem;
}

.subsection-social > a > svg {
  color: var(--done-color-eaeaf6);
  width: 100%;
  height: 100%;
}

.done-callback {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.done-callback-fix {
  background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  color: var(--done-color-000000);
  text-align: left;
  cursor: pointer;
  z-index: 5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8.6rem;
  display: flex;
  position: fixed;
  bottom: 0;
}

.done-callback-fix svg {
  width: 3rem;
  height: 1em;
}

.done-callback-fix-dark {
  background: var(--done-color-000000) 0% 0% no-repeat padding-box;
  color: var(--done-color-ffffff);
}

.done-scroll {
  z-index: 100;
  background: none;
  height: 1.2rem;
  position: fixed;
  bottom: 0;
}

.done-scroll-plain {
  background: var(--done-color-00ff89);
  height: 1.2rem;
}

.done-scroll-desktop {
  z-index: 1000;
  flex-direction: column-reverse;
  width: 1.6rem;
  height: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

.done-scroll-desktop-plain {
  background: var(--done-color-00ff89);
  width: 100%;
}

.done-footer {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  flex-direction: column-reverse;
  margin-bottom: 7.6rem;
  display: flex;
}

.done-footer-logos {
  flex-direction: column-reverse;
  display: flex;
}

.done-footer-contacts {
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 3.2rem;
  display: flex;
  position: relative;
}

.done-footer-contacts .done-footer-mentions-mobile {
  margin: 0 0 6.4rem;
}

.done-footer-contacts .done-footer-mentions-mobile-social {
  width: 100%;
}

.done-footer-contacts .done-footer-mentions-mobile-social .done-footer-social-mobile {
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.done-footer-contacts .done-footer-mentions-mobile svg {
  height: 4rem;
}

.done-footer-contact {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.done-footer-contact-separator {
  background-color: var(--done-color-000000);
  width: 41%;
  height: 2px;
  margin: 2.4rem auto;
}

.done-footer-contact-separator-mobile {
  display: block;
}

.done-footer-arrow {
  width: 29.25%;
  display: none;
}

.done-footer-arrow-mobile {
  width: 100%;
}

.done-footer-social {
  text-align: center;
  justify-content: space-between;
  width: 100%;
  max-width: 18rem;
  margin-bottom: 2.4rem;
  display: none;
}

.done-footer-social-facebook, .done-footer-social-linkedin, .done-footer-social-youtube, .done-footer-social-glassdoor, .done-footer-social-instagram {
  width: 20%;
}

.done-footer-social-facebook-mobile svg, .done-footer-social-linkedin-mobile svg, .done-footer-social-youtube-mobile svg, .done-footer-social-glassdoor-mobile svg, .done-footer-social-instagram-mobile svg {
  height: 1.6rem;
}

.done-footer-social-instagram svg {
  height: 3.2rem;
  padding-right: 1rem;
}

.done-footer-social-mobile {
  justify-content: space-between;
  width: 100%;
  margin-top: 2.9rem;
  display: flex;
}

.done-footer-links {
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 4.2rem;
  display: flex;
}

.done-footer-link {
  flex-basis: 50%;
}

.done-footer-link:nth-child(2n) {
  text-align: right;
}

.done-footer-button {
  justify-content: flex-end;
  height: 15.7rem;
  display: flex;
}

.done-footer-button-mobile {
  justify-content: center;
  height: 10rem;
  display: flex;
}

.done-footer-mentions {
  flex-basis: 50%;
  display: none;
}

.done-footer-mentions-mobile {
  justify-content: space-between;
  margin: 6rem 0 3.6rem;
  display: flex;
}

.done-footer-mentions-mobile-social {
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: 8.3rem;
  display: flex;
}

.done-footer-legal {
  flex-wrap: wrap;
  justify-content: space-between;
  height: calc(50% - 3.2rem);
  display: flex;
}

.done-footer-legal-mobile {
  flex-direction: column;
  line-height: 4.2rem;
  display: flex;
}

.done-footer-legal-link {
  text-align: end;
}

.done-footer-legal-link-cookie:hover {
  cursor: pointer;
  text-decoration: underline;
}

.done-footer-legal-link svg {
  height: 1.7rem;
}

.pagination-container {
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  margin: 4rem auto;
  display: flex;
}

.pagination-button {
  color: var(--done-color-333834);
  width: 3rem;
}

.pagination-button.disabled svg {
  display: none;
}

.pagination-button:hover, .pagination-button:focus {
  color: var(--done-color-00ff89);
}

.pagination-button svg {
  width: 3rem;
  height: 3rem;
}

.pagination-number {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  text-align: center;
  width: 2.8rem;
  height: 2.8rem;
}

.pagination-number:hover, .pagination-number:focus {
  background-color: var(--done-color-00ff89);
  border: 1px solid var(--done-color-00ff89);
  text-decoration: none;
}

.pagination-numbers {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.pagination-number {
  border: 1px solid var(--done-color-333834);
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-number.active {
  background-color: var(--done-color-333834);
  color: var(--done-color-ffffff);
  border: 1px solid var(--done-color-333834);
}

.pagination-number.active:hover, .pagination-number.active:focus {
  background-color: var(--done-color-00ff89);
  border: 1px solid var(--done-color-00ff89);
}

.done-animated-picto-white {
  background-image: url("animated-picto-white.d5f1445c.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  width: 100%;
  padding-top: 8.1rem;
}

@media only screen and (width >= 768px) {
  .done-section-step-one {
    width: 34.38rem;
  }

  .done-section-step-image {
    height: 12rem;
  }

  .done-section-step-two {
    width: 45.564rem;
  }

  .done-section-step-three {
    width: 49.356rem;
  }

  .done-section-step-subtitle {
    margin-top: 3.4rem;
    font-size: 2.5rem;
  }

  .subsection-title {
    gap: 5.8rem;
  }

  .subsection-social {
    padding: 3.2rem 5rem;
  }
}

@media only screen and (width >= 992px) {
  svg {
    height: 7.9rem;
  }

  .btn {
    max-width: 37rem;
    height: 7.5rem;
    padding: 0 4rem;
  }

  .btn-large {
    width: 375px;
    max-width: 375px;
  }

  .done-modal-close {
    width: 8.4rem;
    height: 6.4rem;
    position: absolute;
  }

  .done-modal-close svg {
    width: 100%;
    height: 100%;
  }

  .done-modal-submit {
    padding: 2rem 7rem;
  }

  .done-modal-content {
    width: auto;
  }

  .done-modal-content-title-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-140) / var(--done-line-spacing-80) var(--done-font-family-signal-mono);
  }

  .done-modal-content .hs-input {
    background: var(--done-color-ffffff);
    text-transform: uppercase;
    margin: 0;
    padding-left: 2.2rem;
  }

  .done-modal-content-economy {
    text-align: center;
  }

  .done-modal-content-white {
    max-width: 70rem;
  }

  .done-modal-content-callback {
    min-width: 70rem;
  }

  .done-modal-content-section {
    padding: 1.6rem 5rem 0;
  }

  .done-input-group {
    justify-content: flex-start;
  }

  .done-input-group-input span, .done-input-group-input-icon span {
    display: flex;
  }

  .done-nav {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    align-items: center;
    padding: 3.2rem;
  }

  .done-nav-account svg {
    width: 3.9rem;
    height: 3.9rem;
    margin-right: 1.6rem;
  }

  .done-nav > a.logo {
    width: 12.5%;
    margin-right: 3.2rem;
  }

  .done-nav-links {
    display: flex;
  }

  .done-nav-mobile {
    display: none;
  }

  .done-nav-scroll-down, .done-nav-scroll-up {
    padding: 0 2.8rem;
  }

  .done-section {
    padding: 4.8rem;
    position: relative;
  }

  .done-section-accueil {
    padding-top: 10.8rem;
  }

  .done-section-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-100) / var(--done-font-size-100) var(--done-font-family-signal-mono);
  }

  .done-section-title-simulator {
    max-width: 75%;
  }

  .done-section-title-vision-svg {
    display: none;
  }

  .done-section-title svg {
    height: 8rem;
  }

  .done-section-title-second {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    margin: 4.4rem 0;
  }

  .done-section-title-third {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-30) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-32);
  }

  .done-section-title-timeline {
    flex-direction: row;
    align-items: center;
    margin-bottom: 6.6rem;
  }

  .done-section-title-shared {
    flex-direction: row;
  }

  .done-section-subtitle {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-28) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-22);
    max-width: 565px;
    padding: 0;
  }

  .done-section-step {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
  }

  .done-section-step-one {
    width: 51.57rem;
    margin-bottom: 10rem;
  }

  .done-section-step-image {
    height: 18rem;
  }

  .done-section-step-two {
    align-self: flex-end;
    width: 68.346rem;
  }

  .done-section-step-three {
    width: 74.034rem;
  }

  .done-section-step > div {
    margin: 3.2rem 0;
  }

  .done-section-button-group {
    flex-wrap: nowrap;
    justify-content: center;
    gap: 9.5rem;
  }

  .done-section-step .done-section-button-group {
    margin-top: 12rem;
  }

  .done-section-photos-mobile {
    display: none;
  }

  .done-section-photos-desktop {
    object-fit: cover;
    display: block;
    transform: translateY(101%);
  }

  .done-section-photos-desktop-reveal {
    transition: all .5s;
    transform: none;
  }

  .done-section-photos-desktop-reveal:nth-child(2) {
    transition-delay: .2s;
  }

  .done-section-photos-desktop-reveal:nth-child(3) {
    transition-delay: .4s;
  }

  .done-section-cta {
    background: var(--done-color-333834) 0% 0% no-repeat padding-box;
    color: var(--done-color-eaeaf6);
    flex-wrap: wrap;
    justify-content: center;
    padding: 10rem;
    display: flex;
  }

  .done-section-cta-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    text-align: center;
    flex-basis: 100%;
  }

  .done-section-cta-logo {
    max-width: 230px;
    height: 15.5rem;
    margin: 10rem 0;
  }

  .done-section-cta-logo svg {
    width: 100%;
    height: 100%;
  }

  .done-section-timeline {
    flex-direction: column;
  }

  .done-section-timeline-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-32);
    color: var(--done-color-000000);
    flex-direction: row;
    justify-content: flex-start;
    margin: 4.4rem 0;
    display: flex;
  }

  .done-section-timeline-title-detail {
    opacity: 1;
    width: 25%;
    margin: 0;
    display: none;
  }

  .done-section-timeline-title-detail br, .done-section-timeline-title-detail-active, .done-section-timeline-title-detail-hover {
    display: block;
  }

  .done-section-timeline-description {
    justify-content: flex-start;
    margin: 4.4rem 0 0;
    display: flex;
  }

  .done-section-timeline-description-detail {
    width: calc(25% - 1.6rem);
    margin-right: 1.6rem;
    padding-right: 5rem;
    display: none;
  }

  .done-section-timeline-description-detail-active, .done-section-timeline-description-detail-hover {
    display: block;
  }

  .done-section-timeline-list {
    flex-direction: row;
    order: 0;
    justify-content: space-between;
    margin: 1rem 0 0;
    display: flex;
  }

  .done-section-timeline-detail {
    flex-grow: 1;
    position: relative;
  }

  .done-section-timeline-detail:before {
    content: "";
    border: .1rem solid var(--done-color-000000);
    box-sizing: border-box;
    cursor: pointer;
    width: 4.4rem;
    height: 4.4rem;
    display: block;
  }

  .done-section-timeline-detail-active:before {
    background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
    border: none;
  }

  .done-section-timeline-detail:after {
    content: "";
    border-top: .125rem dashed var(--done-color-000000);
    border-left: none;
    width: calc(100% - 4.4rem);
    height: .1rem;
    position: absolute;
    top: 2.2rem;
    left: 4.4rem;
  }

  .done-section-button {
    margin: 6.6rem 0 0;
  }

  .done-section-paragraph {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-22);
    color: var(--done-color-000000);
    text-align: left;
  }

  .done-section-form {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    width: 100%;
    display: flex;
  }

  .done-section-form-group {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    flex-basis: calc(50% - 2.5rem);
    gap: 1.6rem;
  }

  .done-section-form-group.center {
    margin: 0 auto;
  }

  .done-section-form-group label {
    margin-top: 2rem;
  }

  .done-section-form-button {
    flex-basis: 100%;
    justify-content: center;
    padding: 7.5rem 0 0;
    display: flex;
  }

  .done-section-cover {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .done-section-unsold {
    gap: 2.4rem;
  }

  .done-section-unsold-first {
    width: 50%;
  }

  .done-section-unsold-title {
    text-align: left;
    font-size: var(--done-font-size-80);
    line-height: var(--done-line-spacing-80);
    max-width: 650px;
    margin-bottom: 0;
  }

  .done-section-unsold-button {
    margin-bottom: 0;
    padding-top: 7.6rem;
    display: block;
  }

  .done-section-unsold-button-mobile {
    display: none;
  }

  .done-section-unsold-second {
    text-align: left;
    flex-basis: 450px;
    margin-left: 5rem;
  }

  .done-section-unsold-second-title {
    margin-top: 15rem;
  }

  .done-section-impact {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

  .done-section-impact-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    text-align: center;
  }

  .done-section-impact-list {
    flex-wrap: nowrap;
    flex-basis: 100%;
    display: flex;
  }

  .done-section-impact-detail {
    text-align: center;
    margin-bottom: 0;
  }

  .done-section-impact-detail svg {
    width: 10rem;
  }

  .done-section-impact-detail-number {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    padding: 1.6rem 0 2.4rem;
  }

  .done-section-impact-detail-text {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
  }

  .done-section-company {
    background-color: var(--done-color-00ff89);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 11rem;
    padding: 0 10rem;
    display: flex;
  }

  .done-section-mini {
    flex-direction: row;
    padding: 6.8rem 0;
  }

  .done-section-mini-picture svg {
    width: 12.1rem;
    display: block;
  }

  .done-section-mini-picture-image {
    max-width: 64rem;
    max-height: 64rem;
    margin-left: 5rem;
  }

  .done-section-mini-text {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-22);
    flex: 1;
    align-self: center;
    max-width: 60rem;
    padding: 0 12rem 0 1rem;
  }

  .done-section-mini-description {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-22) / var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-22);
    align-self: flex-end;
  }

  .done-section-sub {
    flex-direction: row;
    align-items: stretch;
  }

  .done-section-sub-part {
    flex-basis: 33.33%;
  }

  .done-section-sub-part-icon-desktop, .done-section-sub-part-icon-desktop-small {
    display: block;
  }

  .done-section-sub-part-icon-mobile {
    display: none;
  }

  .done-section-sub-impact-detail {
    margin-top: 0;
  }

  .done-section-sub-impact-detail-number {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-80) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
  }

  .done-section-sub-impact-detail-text {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-25) var(--done-font-family-signal-mono);
  }

  .done-section-avantages {
    flex-direction: row;
  }

  .done-section-avantages-text {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-100) / var(--done-font-size-100) var(--done-font-family-signal-mono);
    width: 50%;
  }

  .done-section-avantages-text > span {
    color: var(--done-color-00ff89);
  }

  .done-section-avantages-list {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-32);
    width: 35%;
    padding-top: 1.5rem;
  }

  .done-section-avantages-list > ul {
    margin-bottom: 8rem;
  }

  .done-section-avantages-list-text {
    cursor: pointer;
    margin-bottom: 2.5rem;
  }

  .done-section-avantages-list-text:hover, .done-section-avantages-list-text:focus {
    color: var(--done-color-00ff89);
  }

  .done-section-avantages-list-description {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
    color: var(--done-color-000000);
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .done-section-avantages-list-description.visible {
    animation: 2s fadeIn;
    display: block;
  }

  .done-section-avantages-arrow {
    padding-top: 1.5rem;
    display: block;
  }

  .done-section-avantages-arrow > svg {
    width: 9.2rem;
  }

  .done-section-pig {
    height: 16.6rem;
  }

  .done-section-pig-inner {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    width: 400%;
  }

  .done-section-pig-animate svg {
    width: 10rem;
  }

  .done-section-promise {
    height: 10.6rem;
  }

  .done-section-promise-inner {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    color: var(--done-color-000000);
    width: 400%;
    display: flex;
  }

  .done-section-promise-animate svg {
    width: 8rem;
  }

  .done-section-faq {
    width: 100%;
    padding-bottom: 10rem;
  }

  .done-section-faq-home {
    padding-bottom: 0;
  }

  .done-section-faq-title {
    text-align: center;
  }

  .done-section-faq-group {
    height: 11.8rem;
    padding: 0 10rem;
  }

  .done-section-faq-group svg {
    height: 3.8rem;
  }

  .done-section-faq-group-collapse {
    height: 11.8rem;
  }

  .done-section-faq-group-paragraph {
    padding-bottom: 3.2rem;
  }

  .done-section-stories {
    background: var(--done-color-00ff89);
    flex-direction: row;
  }

  .done-section-stories:hover .done-section-stories-container, .done-section-stories-hover .done-section-stories-container {
    background-color: var(--done-color-eaeaf6);
    margin-left: 0;
    transition: all .5s;
  }

  .done-section-stories:hover .done-section-stories-sub-container, .done-section-stories-hover .done-section-stories-sub-container {
    transition: all .4s .2s;
    transform: none;
  }

  .done-section-stories-photo {
    display: revert;
  }

  .done-section-stories-container {
    background: none;
    margin-left: -200%;
    padding: 0;
    transition: all .5s;
    overflow: hidden;
  }

  .done-section-stories-sub-container {
    align-items: flex-start;
    padding: 5rem;
    transform: translateX(150%);
  }

  .done-section-stories-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-140) / var(--done-line-spacing-130) var(--done-font-family-signal-mono);
    color: var(--done-color-eaeaf6);
    letter-spacing: -.15rem;
    text-transform: uppercase;
    text-align: left;
    max-width: 41.5rem;
    margin: 0 0 6.1rem;
    line-height: 1;
  }

  .done-section-stories-subtitle {
    line-height: var(--done-line-spacing-28);
    text-align: left;
    font-size: var(--done-font-size-18);
    max-width: 50rem;
    margin-bottom: 6.1rem;
  }

  .done-section-stories-bottom {
    width: 100%;
  }

  .done-section-stories-bottom .done-page-stories {
    justify-content: unset;
    grid-template-columns: 0 35.8rem;
    padding: 0;
    display: grid;
  }

  .done-section-stories-bottom .done-page-stories-destination {
    text-align: left;
  }

  .done-section-stories-bottom .done-page-stories-destination span {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-28) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-32);
    line-height: var(--done-line-spacing-30);
    margin-bottom: 2.9rem;
  }

  .done-section-stories-bottom .done-page-stories-destination-details {
    max-width: unset;
    font-size: var(--done-font-size-14);
    line-height: var(--done-line-spacing-25);
    margin: 0;
  }

  .done-section-stories-bottom .done-page-stories .btn {
    margin: 6rem 0 0;
  }

  .done-section-half {
    flex-direction: row;
  }

  .done-section-half-one {
    width: 50%;
  }

  .done-section-half-two {
    width: 50%;
    max-width: 46rem;
    padding: 3.2rem;
  }

  .done-section-half-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
  }

  .done-section-half-description {
    font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-2);
  }

  .done-section-half-description p:not(:first-child) {
    margin-top: 2.4rem;
  }

  .done-callback {
    width: 22.8rem;
    right: 12rem;
  }

  .done-callback-fix {
    justify-content: space-evenly;
    width: 22.8rem;
    height: 6.8rem;
    padding: 0 3rem;
  }

  .done-footer {
    background: var(--done-color-eaeaf6) 0% 0% no-repeat padding-box;
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    flex-direction: row;
    gap: 2.4rem;
    width: 100%;
    margin-bottom: 0;
    padding: 5rem;
  }

  .done-footer-logos {
    flex-direction: column;
    flex-basis: 22.85%;
    align-items: center;
    margin-right: 10rem;
    display: flex;
  }

  .done-footer-logo {
    width: 100%;
    margin-top: -10%;
    margin-bottom: 5rem;
  }

  .done-footer-logo svg {
    height: auto;
  }

  .done-footer-arrow {
    width: 12.1rem;
    margin: auto auto 5rem;
    display: block;
  }

  .done-footer-social {
    justify-content: space-between;
    height: 3.2rem;
    display: flex;
  }

  .done-footer-social-facebook, .done-footer-social-linkedin, .done-footer-social-youtube, .done-footer-social-glassdoor {
    width: 25%;
  }

  .done-footer-social-facebook svg, .done-footer-social-linkedin svg, .done-footer-social-youtube svg, .done-footer-social-glassdoor svg {
    height: 2.8rem;
  }

  .done-footer-contacts {
    flex-direction: column;
  }

  .done-footer-contact-separator {
    width: 80%;
  }

  .done-footer-contact-separator-mobile {
    display: none;
  }

  .done-footer-links {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }

  .done-footer-link {
    flex-basis: 50%;
  }

  .done-footer-button {
    justify-content: flex-end;
    margin-top: 3rem;
    display: flex;
  }

  .done-footer-button-mobile {
    display: none;
  }

  .done-footer-mentions {
    flex-basis: 50%;
    display: block;
  }

  .done-footer-mentions-mobile {
    display: none;
  }

  .done-footer-legal {
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(50% - 3.2rem);
    display: flex;
  }

  .done-footer-legal-link {
    flex-basis: 100%;
  }

  .done-footer-legal-link svg {
    height: 1.7rem;
  }

  .done-scroll {
    display: none;
  }

  .done-scroll-desktop {
    display: flex;
  }

  .section-header {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-height: 110rem;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .section-header-vision {
    background-image: url("animated-picto-green.fe65e5a3.gif");
    background-position: center;
    background-size: 84rem;
    flex-direction: column;
    position: relative;
  }

  .section-header-vision-arrow {
    position: absolute;
    bottom: 3rem;
    left: 5rem;
  }

  .section-header-video {
    height: initial;
    min-width: 100%;
    min-height: 100vh;
  }

  .section-ties {
    background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
    justify-content: space-between;
    padding: 10rem;
    display: flex;
  }
}

@keyframes showCookieBanner {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

.pointer {
  cursor: pointer;
}

.cookie-banner {
  z-index: 9999;
  background-color: var(--done-color-ffffff);
  justify-content: center;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-banner.show {
  animation: .5s ease-out showCookieBanner;
}

.cookie-banner-cookies {
  gap: var(--done-line-spacing-28);
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 2rem;
  display: flex;
}

.cookie-banner-cookies-picto {
  flex-basis: 20%;
  justify-content: center;
  display: flex;
}

.cookie-banner-cookies-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-32) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  text-transform: uppercase;
}

.cookie-banner-cookies-text {
  flex-basis: 60%;
}

.cookie-banner-cookies-text p {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
}

.cookie-banner-cookies-action {
  flex-direction: column;
  flex-basis: 20%;
  display: flex;
}

.cookie-banner-cookies-action .btn {
  width: auto;
}

.cookie-banner-cookies-action .btn-fast {
  font-size: var(--done-font-size-16);
  height: 6.6rem;
}

.cookie-banner-cookies-action .btn-oneline {
  white-space: nowrap;
}

@media only screen and (width >= 992px) {
  .cookie-banner-cookies {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    display: flex;
  }
}

@media only screen and (width >= 992px) and (orientation: portrait) {
  .section-header-video {
    min-height: 100vh;
  }
}

@media only screen and (width >= 1200px) {
  .done-section {
    padding: 6.8rem;
  }

  .done-section-accueil {
    padding-top: 16.8rem;
  }

  .done-section-step-one {
    width: 57.3rem;
  }

  .done-section-step-image {
    height: 20rem;
  }

  .done-section-step-two {
    width: 75.94rem;
  }

  .done-section-step-three {
    width: 82.26rem;
  }

  .done-section-stories-arrow {
    display: revert;
    color: var(--done-color-ffffff);
  }

  .done-section-stories-arrow > svg {
    width: 11rem;
  }

  .done-nav {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    padding: 3.5rem 5rem;
  }

  .done-nav > a.logo {
    margin-right: 12rem;
  }

  .done-nav-scroll-down, .done-nav-scroll-up {
    padding: 0 3.5rem;
  }

  .done-section-stories .done-page-stories {
    grid-template-columns: 30rem 35.8rem;
  }
}

@media only screen and (width >= 1480px) {
  .done-section {
    padding: 10rem;
  }

  .done-section-accueil {
    padding-top: 20rem;
  }

  .done-section-step-one {
    width: 63.03rem;
  }

  .done-section-step-image {
    height: 22rem;
  }

  .done-section-step-two {
    width: 83.534rem;
  }

  .done-section-step-three {
    width: 90.486rem;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@media only screen and (width <= 992px) {
  .done-section-timeline-detail-active-mobile:before {
    background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
    border: none;
  }

  .done-campaign-fix {
    left: 0 !important;
  }

  .done-campaign-fix-reduced {
    min-height: 8.6rem;
  }
}

.center {
  text-align: center;
}

.padding-top-medium {
  padding-top: 7rem;
}

#campaign, .campaign-reduced {
  display: none;
}

.reduced-title {
  text-align: center;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  cursor: pointer;
  text-transform: uppercase;
  gap: 2.4rem;
  width: auto;
  display: flex;
  left: 0;
}

.reduced-title-hidden {
  display: none;
}

.campaign-reduce {
  color: var(--done-color-000000);
  cursor: pointer;
  margin: 2rem 3rem auto auto;
  position: absolute;
  top: 0;
  right: 0;
}

.campaign-reduce .reduce-btn-svg {
  width: 4.8rem;
  height: 4.8rem;
}

.campaign-reduce-grey {
  color: var(--done-color-eaeaf6);
}

.done-campaign {
  z-index: 99;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.done-campaign-fix {
  background: var(--done-color-ffffff) 0 0 no-repeat padding-box;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  color: var(--done-color-333834);
  z-index: 2000;
  padding: var(--done-line-spacing-55);
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.done-campaign-fix-img {
  padding: var(--done-line-spacing-30);
}

.done-campaign-fix svg {
  color: var(--done-color-000000);
}

.done-campaign-fix-reduced {
  background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;
}

.done-campaign-fix-reduced .svg-wrapper svg {
  width: 7rem;
  height: 4rem;
}

.done-campaign-fix-reduced .svg-wrapper img {
  height: 5rem;
}

.done-campaign-fix-reduced span {
  margin: auto;
}

.done-campaign-fix-grey {
  background: var(--done-color-333834) 0% 0% no-repeat padding-box;
  color: var(--done-color-eaeaf6);
}

.done-campaign-fix-grey svg {
  color: var(--done-color-eaeaf6);
  fill: var(--done-color-eaeaf6);
}

.done-campaign-fix-banner {
  flex-direction: column;
  gap: 1.6rem;
  display: flex;
}

.done-campaign-fix-banner .campaign-help {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-14) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  text-align: justify;
  display: none;
}

.done-campaign-fix-banner .campaign-date {
  text-transform: uppercase;
}

.done-campaign-fix-banner span {
  font: var(--done-font-style-normal) normal var(--done-font-weight-bold) var(--done-font-size-14) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
}

.done-campaign-fix-banner .vote-link {
  display: none;
}

.done-campaign-fix-banner .btn {
  padding: 0;
}

.done-campaign-fix-banner .btn-primary, .done-campaign-fix-banner .btn-secondary {
  margin-top: 2rem;
}

.done-campaign-fix svg, .done-campaign-fix img {
  max-width: 12.105rem;
  max-height: 8.2rem;
}

.done-campaign-fix-abeilles {
  align-items: left;
  gap: var(--done-line-spacing-20);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.done-campaign-fix-abeilles .campaign-finalist {
  text-transform: uppercase;
}

.done-campaign-fix-abeilles .campaign-help {
  font: var(--done-font-style-normal) normal var(--done-font-weight-medium) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
}

.done-campaign-fix-abeilles .btn {
  padding: 0;
}

.done-campaign-fix-abeilles .vote-link {
  display: none;
}

@media only screen and (width >= 992px) {
  #banner.done-campaign-fix {
    padding: 0 5rem 0 0;
  }

  #banner.done-campaign-fix-reduced {
    padding: 0 3rem;
  }

  .campaign-reduce {
    margin: 0 1.6rem auto auto;
  }

  .campaign-reduce-grey {
    color: var(--done-color-eaeaf6);
  }

  .done-campaign-fix {
    background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;
    color: var(--done-color-000000);
    flex-direction: row;
    justify-content: space-between;
    min-height: 6.8rem;
    padding: 0;
    display: flex;
  }

  .done-campaign-fix-img {
    padding: var(--done-line-spacing-30);
  }

  .done-campaign-fix .svg-wrapper {
    align-items: center;
    margin: 1.2rem;
    display: flex;
  }

  .done-campaign-fix .svg-wrapper img {
    margin-left: 3rem;
  }

  .done-campaign-fix-reduced {
    width: auto;
    left: auto;
    right: 36rem;
  }

  .done-campaign-fix-reduced .svg-wrapper img {
    margin-left: 0;
  }

  .done-campaign-fix-grey {
    background: var(--done-color-333834) 0% 0% no-repeat padding-box;
    color: var(--done-color-eaeaf6);
  }

  .done-campaign-fix-black {
    background: var(--done-color-000000) 0% 0% no-repeat padding-box;
    color: var(--done-color-eaeaf6);
  }

  .done-campaign-fix-abeilles {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
    display: flex;
  }

  .done-campaign-fix-abeilles-brand {
    font-size: var(--done-font-size-28);
  }

  .done-campaign-fix-abeilles .campaign-finalist {
    flex-basis: 25%;
  }

  .done-campaign-fix-abeilles .campaign-help {
    text-transform: uppercase;
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    flex-basis: 18%;
  }

  .done-campaign-fix-abeilles .btn-primary, .done-campaign-fix-abeilles .btn-secondary {
    display: none;
  }

  .done-campaign-fix-abeilles .btn-fast {
    flex-basis: 10%;
  }

  .done-campaign-fix-abeilles .vote-link {
    text-align: center;
    text-transform: uppercase;
    flex-direction: column;
    gap: 1rem;
    display: flex;
  }

  .done-campaign-fix-abeilles .vote-link .btn {
    font-size: var(--done-font-size-28);
    text-decoration: underline;
  }

  .done-campaign-fix-abeilles .vote-link .btn:hover {
    cursor: pointer;
    color: var(--done-color-00ff89);
    text-decoration: none;
  }

  .done-campaign-fix-banner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    display: flex;
  }

  .done-campaign-fix-banner .campaign-date {
    flex-basis: 100%;
    display: flex;
  }

  .done-campaign-fix-banner span {
    font: var(--done-font-style-normal) normal var(--done-font-weight-bold) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-16);
  }

  .done-campaign-fix-banner .campaign-help {
    text-transform: uppercase;
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-16);
    text-align: left;
    flex-basis: 100%;
    display: none;
  }

  .done-campaign-fix-banner .btn-primary, .done-campaign-fix-banner .btn-secondary {
    max-width: 30rem;
    margin: 1rem 2rem 1rem 0;
    display: flex;
  }

  .done-campaign-fix-banner .btn-fast {
    flex-basis: 10%;
  }

  .done-campaign-fix-banner .vote-link {
    text-align: center;
    text-transform: uppercase;
    flex-direction: column;
    gap: 1rem;
    display: none;
  }

  .done-campaign-fix-banner .vote-link .btn {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-28) / var(--done-line-spacing-30) var(--done-font-family-signal-mono);
    width: auto;
    padding: 0 2rem;
    text-decoration: underline;
  }

  .done-campaign-fix-banner .vote-link .btn:hover {
    cursor: pointer;
    color: var(--done-color-00ff89);
    text-decoration: none;
  }

  .done-campaign-fix svg {
    width: 19rem;
    height: 9rem;
  }
}

/*# sourceMappingURL=index.3e042cc2.css.map */
